#acknowledgements-and-about {
  padding-top: 2%;

  display: grid;

  grid-template-columns: 125px auto;

  grid-column-gap: 2px;

  font-size: 16px;
}

.difficulty-info {
  width: 60%;
  font-size: 18px;
}

.difficulty-select {
  width: auto;
  background-color: white;
  border: 1px solid black;
}

.errors {
  display: grid;
}

.help_msgs {
  margin-top: 5px;
  font-style: italic;
  font-size: larger;
}


.help_msgs,
.button,
.signup_submit,
select,
input {
  width: 60%;
}

.login,
.signup {
  display: grid;

  grid-auto-rows: minmax(65px, auto);

  margin-bottom: 0;
  grid-template-columns: 7% 13% [content] auto 13% 7%;
}

.login_box,
.signup_box {
  grid-column: content;
  display: grid;

  grid-template-columns: auto;
  grid-auto-rows: minmax(50px, auto);
}

.login_options {
  display: grid;

  margin: 15px 0 15px 0;
}

.login_type {
  grid-column: content;
  font-size: x-large;
}

.login_label,
.signup_label {
  display: grid;
  padding-top: 20px;
  -webkit-padding-before: 2.5%;
}

.login_role {
  font-family: 'EB Garamond';
  font-size: 32px;
  display: grid;
  align-items: center;
}

.select {
  border: 0;
  border-radius: 0;
}

.signup_title {
  font-family: 'EB Garamond';
  font-size: 32px;
  display: grid;
  align-items: center;
}

/* CSS fixes to deal with incorrect styles */
.signup_submit {
  width: 100%;
  padding: 15px 0;
}

.signup_submit,
.login_submit {
  display: grid;
  justify-content: center;
}

validation-error {
  display: inline-block;
  padding-top: 5px;
  -webkit-padding-before: 0.5%;
  line-height: 40px;
}

.welcome_msg {
  display: grid;
  width: 60%;

  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 18px;
}

.welcome_msg > .headline {
  padding-bottom: 15px;
}

.welcome-msg-text {
  display: grid;

  grid-column: 1;
  grid-row: 2;
}


@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .help_msgs,
  .button,
  .signup_submit,
  select,
  input {
    width: 95%;
  }

  .button {
    height: 40px;
  }
/* 
  select {
    height: 50px;
  }
 */
  #lower-menu {
    margin-top: 0;
    padding: 0;
  }

  .signup_title {
    font-family: 'EB Garamond';
    font-size: 32px;
  }

  .welcome_msg,
  .difficulty-info {
    width: 100%;
  }
}


@media (max-width: 340px) {
  #acknowledgements-and-about {
    grid-template-columns: auto;
  }
}
  

@media only screen and (max-width: 768px) {
  .button {
    height: 40px;
  }

  .help_msgs,
  .button,
  .signup_submit,
  select,
  input {
    width: 95%;
  }

  #lower-menu {
    margin-top: 0;
    padding: 0;
  }

  .login,
  .signup,
  .login_box,
  .signup_box {
    display: flex;
    flex-direction: column;

    margin-left: 20px;
    margin-right: 20px;
  }

  .filter {
    display: none;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-items: center;
  }
/* 
  select {
    height: 50px;
  } */

  .signup {
    margin-top: 15px;
  }

  .signup_box > input,
  .login_box > input {
    height: 45px;
  }

  .signup_title {
    font-family: 'EB Garamond';
    font-size: 32px;
  }

  .welcome_msg,
  .difficulty-info {
    width: 100%;
  }

  .welcome-headline {
    padding-top: 25px;
  }

  .welcome_msg > .headline {
    padding-bottom: 0;
  }
}